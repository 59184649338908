import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface IRequiredElementProps extends WithTranslation {}

export interface IRequiredElementData {
    order_number: string;
    additive: string;
    country_name: string;
}

export interface IRequiredElementState {
    company_name: string;
    requiredElementData: IRequiredElementData;
    initialRequiredElementData: IRequiredElementData;
    updateIsActive: boolean;
    msg: string;
    allCountries: any;
    checkedCountries: {
        country_id: number;
        country_name: string;
        short_name: string;
    }[];
}

class RequiredElement extends React.Component<IRequiredElementProps, IRequiredElementState> {
    constructor(props: IRequiredElementProps) {
        super(props);
        const initialData = {
            order_number: "0",
            additive: "0",
            country_name: "Österreich",
        };
        this.state = {
            company_name: sessionStorage.getItem('codeClientNumber') ?? "0",
            requiredElementData: { ...initialData },
            initialRequiredElementData: { ...initialData },
            updateIsActive: false,
            msg: "",
            allCountries: [],
            checkedCountries: [],
        };
    }

    componentDidMount() {
        this.getCountries();
        this.getCheckedCountries();
        if (this.state.company_name !== "0") {
            this.getRequiredElementData();
        }
        if ((this.state.requiredElementData === undefined) || (this.state.requiredElementData === null)) {
            this.insertRequiredElementData();
        }
    }

    componentDidUpdate(prevProps: Readonly<IRequiredElementProps>, prevState: Readonly<IRequiredElementState>, snapshot?: any): void {
        if (this.state.msg) {
            setTimeout(() => {
                this.setState({ msg: "" });
            }, 5000);
        }
        this.state.checkedCountries.length === 0 && this.setState({ checkedCountries: [{ country_id: 1, country_name: "Österreich", short_name: "AT" }] });
    }

    getRequiredElementData = async () =>
    {
        const { t } = this.props;
        try {
            const response = await fetch(`https://returnmyparcel.at/Required_element.php?company_name=${this.state.company_name}`);
            const data = await response.json();
            if (data.length > 0) {
                this.setState({
                    requiredElementData: data[0],
                    initialRequiredElementData: { ...data[0] },
                    updateIsActive: false,
                });
            }
        } catch (error) {
            console.error(t('Error updating data'), error);
        }
    }

    private updateRequiredElementData = async () =>
    {
        const { t } = this.props;
        try {
            const response = await fetch('https://returnmyparcel.at/Required_element.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'modify',
                    company_name: this.state.company_name,
                    ...this.state.requiredElementData,
                }),
            });
            const data = await response.json();
            if (data.message === 'Element modified successfully.') {
                this.setState({
                    msg: t('Daten erfolgreich aktualisiert'),
                    updateIsActive: false,
                });
            } else {
                this.setState({
                    msg: t('Error updating data'),
                    updateIsActive: false,
                });
            }
        } catch (error) {
            console.error(t('Error updating data'), error);
            this.setState({ msg: t('Error updating data') });
        }
        this.updateCheckedCountries();
    }

    handleCheckboxChange = (key: keyof IRequiredElementData, checked: boolean) => {
        this.setState(prevState => {
            const updatedData = {
                ...prevState.requiredElementData,
                [key]: checked ? "1" : "0",
            };
            const isUpdated = this.isUpdateIsActive(updatedData, prevState.initialRequiredElementData);
            return {
                requiredElementData: updatedData,
                updateIsActive: isUpdated,
            };
        });
    }

    isUpdateIsActive = (updatedData: IRequiredElementData, initialData: IRequiredElementData) => {
        return Object.keys(updatedData).some(key => updatedData[key as keyof IRequiredElementData] !== initialData[key as keyof IRequiredElementData]);
    }

    private insertRequiredElementData = async () =>
    {
        const { t } = this.props;
        try {
            const response = await fetch('https://returnmyparcel.at/Required_element.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'add',
                    company_name: this.state.company_name,
                    ...this.state.requiredElementData,
                }),
            });
            const data = await response.json();
        } catch (error) {
            console.error(t('Error updating data'), error);
            this.setState({ msg: t('Error updating data') });
        }
    }

    private getCountries = async () =>
    {
        const { t } = this.props;
        try {
            const response = await fetch(
                'https://ws.paketomat.at/HUB/getCountries.php?return_aktiv=1',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await response.json();
            // add Litauen to the list of countries
            data.push({ ID: 999, n_long: 'Litauen', n_short: 'LT' });
            this.setState({
                allCountries: data.filter(
                    (country: any) =>
                        country.n_long === 'Österreich' ||
                        country.n_long === 'Belgien' ||
                        country.n_long === 'Deutschland' ||
                        country.n_long === 'Dänemark' ||
                        country.n_long === 'Estland' ||
                        country.n_long === 'Finnland' ||
                        country.n_long === 'Frankreich' ||
                        country.n_long === 'Irland' ||
                        country.n_long === 'Lettland' ||
                        country.n_long === 'Litauen' ||
                        country.n_long === 'Luxemburg' ||
                        country.n_long === 'Niederlande' ||
                        country.n_long === 'Schweden' ||
                        country.n_long === 'Slowakische Republik' ||
                        country.n_long === 'Tschechische Republik' ||
                        country.n_long === 'Spanien'
                        
                ),
            });
        } catch (error) {
            console.error(t('Error updating data'), error);
        }
    }

    private getCheckedCountries = async () =>
    {
        const { t } = this.props;
        try {
            const response = await fetch(`https://returnmyparcel.at/countries_api.php?company_name=${this.state.company_name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.status === 'success') {
                this.setState({ checkedCountries: data.data });
                data.data.length === 0 && this.addAustria();
            } else {
                console.error(t('Failed to fetch countries:'), data.message);
            }
        } catch (error) {
            console.error(t('Error updating data'), error);
        }
    }

    handleCheckboxChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checkedCountries } = this.state;
        const countryId = this.state.allCountries.find((country: any) => country.n_long === event.target.value)?.ID;
        const countryName = event.target.value;
        const short_name = this.state.allCountries.find((country: any) => country.n_long === event.target.value)?.n_short;
        const countryIndex = checkedCountries.findIndex((checkedCountry: any) => checkedCountry.country_id === countryId);

        if (countryIndex === -1) {
            checkedCountries.push({ country_id: countryId, country_name: countryName, short_name: short_name });
        } else {
            checkedCountries.splice(countryIndex, 1);
        }

        this.setState({ checkedCountries, updateIsActive: true });
    }

    updateCheckedCountries = async () =>
    {
        const { t } = this.props;
        try {
            const response = await fetch('https://returnmyparcel.at/countries_api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_name: this.state.company_name,
                    data: this.state.checkedCountries,
                }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                this.setState({
                    msg: t('Daten erfolgreich aktualisiert'),
                    updateIsActive: false,
                });
            } else {
                this.setState({
                    msg: t('Error updating data'),
                    updateIsActive: false,
                });
            }
        } catch (error) {
            console.error(t('Error updating data'), error);
            this.setState({ msg: t('Error updating data') });
        }
    }

    private addAustria = async () =>
    {
        const { t } = this.props;
        const bodyRequest = {
            company_name: this.state.company_name,
            data: [
                {
                    country_id: 1,
                    country_name: "Österreich",
                    short_name: "AT"
                }
            ]
        };
        try {
            const response = await fetch('https://returnmyparcel.at/countries_api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyRequest),
            });
            const data = await response.json();
            if (data.status === 'success') {
                this.setState({
                    msg: t('Daten erfolgreich aktualisiert'),
                    updateIsActive: false,
                });
            } else {
                this.setState({
                    msg: t('Error updating data'),
                    updateIsActive: false,
                });
            }
        } catch (error) {
            console.error(t('Error updating data'), error);
            this.setState({ msg: t('Error updating data') });
        }
    }

    render() {
    const { t } = this.props;

        const { requiredElementData, updateIsActive, checkedCountries } = this.state;
        const isAustriaChecked = checkedCountries.some(country => country.country_name === "Österreich");
        const disableAustria = isAustriaChecked && checkedCountries.length === 1;

        return (
            <div className=" bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2 w-1/2 mx-auto ">
                <h1 className='className="text-base text-slate-800 truncate hover:text-slate-900 font-semibold font-pluto'>
                    {t('Pflichtfelder')}
                </h1>
                <ul className="divide-y divide-slate-100">
                    {Object.keys(requiredElementData).map(key =>
                        key !== 'country_name' &&
                        key !== 'name' &&
                        key !== 'street' &&
                        key !== 'postcode' &&
                        key !== 'place' &&
                        key !== 'country' &&
                        key !== 'email' ? (
                            <li
                                key={key}
                                className="flex items-center gap-4 px-4 py-3"
                            >
                                <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                                    <label
                                        className="w-full text-base font-medium text-slate-800 truncate cursor-pointer hover:text-slate-900  font-pluto"
                                        htmlFor={`checkbox-${key}`}
                                    >
                                        {key === 'order_number' &&
                                            t('Bestellnummer')}
                                        {key === 'additive' && t('Zusatz')}
                                    </label>
                                </div>
                                <div className="relative flex flex-wrap items-center">
                                    <input
                                        className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-red-200 checked:after:left-4 checked:after:bg-red-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-red-300 checked:after:hover:bg-red-600 focus:outline-none checked:focus:bg-red-400 checked:after:focus:bg-red-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300  font-pluto"
                                        type="checkbox"
                                        id={`checkbox-${key}`}
                                        checked={
                                            requiredElementData[
                                                key as keyof IRequiredElementData
                                            ] === '1'
                                        }
                                        onChange={e =>
                                            this.handleCheckboxChange(
                                                key as keyof IRequiredElementData,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </div>
                            </li>
                        ) : null
                    )}
                </ul>

                <div className="flex flex-col gap-1 my-4">
                    <label
                        className='className="text-base text-slate-800 truncate cursor-pointer hover:text-slate-900 font-semibold'
                        htmlFor="countries"
                    >
                        {t('Countries')}
                    </label>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 my-4">
                        {this.state.allCountries.map((country: any) => (
                            <label
                                key={country.ID}
                                className="flex items-center gap-2 cursor-pointer text-base font-medium text-slate-800 truncate hover:text-slate-900"
                            >
                                <input
                                    disabled={
                                        country.n_long === 'Österreich' &&
                                        disableAustria
                                    }
                                    className="h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors
                                        checked:border-red-500 checked:bg-red-500 checked:hover:border-red-600 checked:hover:bg-red-600 focus:outline-none
                                        checked:focus:border-red-700 checked:focus:bg-red-700 focus-visible:outline-none 
                                        disabled:cursor-not-allowed disabled:bg-red-500 disabled:border-red-500 disabled:text-gray-500"
                                    type="checkbox"
                                    value={country.n_long}
                                    checked={checkedCountries.some(
                                        (checkedCountry: any) =>
                                            checkedCountry.country_id ===
                                            country.ID
                                    )}
                                    onChange={this.handleCheckboxChangeCountry}
                                />
                                <span>{country.n_long}</span>
                            </label>
                        ))}
                    </div>
                </div>

                <div>
                    <p className="text-gray-700 text-xs italic  font-pluto">
                        {t(
                            '*Note: Update button will be enabled only if there are changes'
                        )}
                    </p>
                </div>
                <button
                    className="bg-[#dc0032] hover:animate-pulse text-white font-pluto font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-[#e6e7e8] disabled:text-[#808285] "
                    disabled={!updateIsActive}
                    onClick={this.updateRequiredElementData}
                >
                    {t('Update')}
                </button>
                {this.state.msg && (
                    <div className="bg-[#e6e7e8] text-[#dc0032] px-4 py-3 rounded relative mt-4  font-pluto">
                        {this.state.msg}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(RequiredElement);